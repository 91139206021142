.acc-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  >div {
    display: flex;
    flex-direction: column;
    .style-name {
      max-width: 90px;
      margin-top: 8px;
      border-radius: 20px;
      background-color: #B7625F;
      color: white;
      padding: 0px 4px;
    }
  }

  .goods-icon{
    width: 120px;
    background-image: url("../../../../../images/items/item_bg.png");
    background-size: 100% 100%;
    cursor: pointer;
    margin: 0 20px 0 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    >img{
      width: 80%;
      display: block;
    }
  }
}
.supply-con {
  .acc-info {
    padding: 0 8px;
    display: flex;

    .icon-img {
      background-image: url("../../../../../images/items/item_bg.png");
      background-size: 100% 100%;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      >img {
        width: 90%;
      }
    }
    >.acc-name {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 20px;
      color: #999999;
      p {
        display: flex;
        align-items: center;
      }
      >div {
        display: flex;
        >p span {
          font-size: 18px;
          color: #FF8801;
        }
      }
    }
  }
  .stock-con {
    margin-top: 24px;
    background-color: #F5F5F5;
    padding: 8px;
    >.stock-bar {
      display: flex;
      justify-content: space-between;
    }
    .stock-list {
      min-height: 350px;
      max-height: 350px;
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5px;
      overflow-y: scroll;
    }
  }
  .operating {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    >.settle-accounts {
      color: #999999;
      >span {
        color: #FF901A;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
.buying-container {
  .ant-modal-footer {
    display: none;
  }
}
