@keyframes showBar {
  from{opacity: 0; height: 0}
  to{opacity: 1; height: 64px}
}
.sort-tab-container{
  animation: showBar .4s ease-out forwards;
  height: 68px;
  box-sizing: border-box;
  padding: 0 20px;
  background-color: #3C3C3C;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filter-sort{
    font-size: 14px;
    cursor: pointer;
    color: #666666;
    & .asc {transform: rotate(180deg);display: inline-block; }
    & .des {transform: rotate( 0deg); display: inline-block;}
  }
  .filter-sort-active{
    font-size: 14px;
    cursor: pointer;
    color: rgba(45, 175, 97, 1);
    & .asc {transform: rotate(180deg);display: inline-block; }
    & .des {transform: rotate( 0deg); display: inline-block;}
  }
  .sort-price-filter{
    width: 580px;
    height: 100%;
    display: flex;
    align-items: center;
    >.sort-tab-pattern,.price-filter{
      margin-left: 15px;
    }
    .price-filter-btn{
      width: 68px;
      background-color: #818181;
      border: none;
      color: white;
    }
  }
  .sort-wear-filter{
    width: 265px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .wear-filter-btn{
      width: 68px;
      margin-left: 10px;
      background-color: #818181;
      border: none;
      color: white;
    }
  }
}
