.offer-list-item{
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  >.message {
    color: #fff;
    font-size: 14px;
    width: 240px;
  }
  >.off-type {
    color: #fff;
    font-size: 14px;
  }
  >.handle-box {
    width: 40%;
    display: flex;
    align-items: center;
    height: 70%;
    position: absolute;
    background-color: #2ca05b;
    border-radius: 4px;
    right: 20px;
    padding: 0 15px;
    text-decoration: underline;
    .text {
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      > .icon-arrow-right {
        font-size: 12px;
      }
    }
    .text:hover {
      color: #fff;
    }
   
  }
}