.on-shelf-prompt {
  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 31px;
    background: #FFF3D4;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 12px;
    color: #FF6C14;

    > .prompt-icon {
      display: block;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      background-image: url('../../images/onShelf/gantanhao@2x.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  > .flow-chart {
    width: 588px;
    margin: 55px auto 0 auto;

    > img {
      width: 100%;
    }
  }

  > .btn {
    cursor: pointer;
    width: 126px;
    height: 50px;
    background: #2DAF61;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    margin: 60px auto 10px auto;
  }
}
