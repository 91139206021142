@mixin grid {
    display: inline-grid;
    justify-content: space-around;
    align-items: center;
}

.image-container {
    width: 274px;
    height: 219px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 5px;
    overflow: hidden;
    box-shadow: 0px 18px 21px 0px rgba(0, 1, 1, 0.27);

    .image-head {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #C9C9C9;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        font-weight: 600;

        .image-head-text {
            padding-left: 10px;
            &.cur{
                color: #2dAf61;
            }
        }

        .item.arrow-bottom {
            margin-right: 10px;
        }

        .item {
            width: 5px;
            height: 5px;
            border: 1px solid;
        }

        .arrow-bottom {
            font-size: 0;
            line-height: 0;
            border-width: 6px 5px 0 5px;
            border-color: black;
            border-bottom-width: 0;
            border-style: dashed;
            border-top-style: solid;
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    .image-body {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;

        & img {
            width: 68px;
            height: 38px;
            min-width: 68px;
            min-height: 38px;
            cursor: pointer;
            background-color: #000;
            opacity: 0.4;
            // filter: alpha(opacity=80);
            box-sizing: border-box;
            &.active {
                opacity: 1;
            }
        }

    }
}

.image-container:hover {
    overflow: inherit;
    border-color: transparent;
    z-index: 5;

    .item.arrow-bottom {
        border-top-color: #2dAf61;
    }

    .image-body {
        box-shadow: 1px 6px 8px 3px #b3b0b0;
        border-radius: 0 0px 4px 4px;
    }
}


.image-body .images:hover {
    opacity: 1;
}
// .image-body .images:visited {
//     opacity: 1;
// }


