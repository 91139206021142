.top-message {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #425d89;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .75;
  border-radius: 4px;
  margin-bottom: 20px;
  > .t-text {
    margin: 0 auto;
    font-size: 14px;
    color: #fff;
  }
  > .join-steam-tips {
    position: absolute;
    right: 40px;
    .t-title {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      > .icon-jinggao {
        color: #4bbd78;
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
}