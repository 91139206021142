.not-found {
  width:  100%;
  height: 40rem;
  background-color: #d5d5d5;
  h1 {
    color: #fc9a2b;
    font-weight: 900;
    line-height: 40rem;
    text-align: center;
    font-size: 6rem;
  }
}