.csgo-items {
    position: relative;
    width: 19.6%;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px 10px 5px 10px;

    ._img {
        cursor: pointer;
        position: relative;
        opacity: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 160px;
        padding: 20px 10px;
        box-sizing: border-box;
        background-image: url("../../../images/items/item_bg.png");
        background-size: 100% 100%;
        transition: 1s opacity;

        .item-img {
            width: 80%;
            height: 100%;
        }

        > .item-quality {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 12px;
            display: flex;
            align-items: center;

            > .item_quality {
                background-color: #2A2A2A;
                padding: 4px 5px;
                color: #8F98A0;
            }

            > .item_paint_seed {
                background: #000000;
                opacity: 0.65;
                padding: 4px 6px;
                color: #DBDBDB;
            }

            > .item_paint_index {
                background: #000000;
                opacity: 0.65;
                padding: 4px 6px;
                color: #DBDBDB;
                font-weight: 900;
            }

            > .link-icon {
                cursor: pointer;
                width: 17px;
                margin-left: 5px;
            }
        }

        .subscript {
            z-index: 10;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 2px;
            color: #fff;
            line-height: 18px;
            text-align: center;
            height: 18px;
            font-size: 12px;
            transform-origin: top right;
        }

        > .item-wear-container {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 32px;
            background-color: #393939;
        }

        > .parsing-btn, > .d3-btn, > .name-label {
            cursor: pointer;
            height: 22px;
            line-height: 20px;
            padding: 0 5px;
            font-size: 12px;
            color: #EDEDED;
            background: rgba(168, 168, 174, .4);
            border: 1px solid rgba(78, 76, 89, .4);
            box-sizing: border-box;
            width: initial;
            position: absolute;
            z-index: 10;
            bottom: 38px;
            right: 6px;
        }

        > .d3-btn {
            height: 18px;
            line-height: 16px;
            padding: 0 3px;
            bottom: 62px
        }

        > .name-label {
            height: 18px;
            line-height: 16px;
            padding: 0 3px;
            bottom: 80px;
            background: none;
            border: none;
            right: 3px;

            > i {
                color: orange;
            }
        }
    }

    .item-name {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        height: 20px;
        line-height: 20px;
        font-weight: 500;
        margin: 10px 0 10px 0;
    }

    .item-name:hover {
        color: #2EB062;
    }

    .price {
        color: #FF8802;
        font-size: 18px;
        font-weight: 500;
        height: 24px;

        small {
            font-size: 16px;
        }
    }

    .end-time {
        position: absolute;
        bottom: 8px;
        right: 15px;
        display: flex;
        color: #9A9A9A;
        align-items: center;

        .icon-time {
            width: 15px;
            height: 14px;
            margin-right: 6px;
        }
    }

    .item-select {
        position: absolute;
        bottom: 10px;
        right: 15px;
        width: 18px;
        height: 18px;
        border: 2px solid #CCCCCC;
        border-radius: 4px;

        .icon-hook-selected {
            display: none;
            font-weight: bold;
            transform: scale(.9);
            transform-origin: top left;
        }

        &.selected {
            border-color: #2DAF61;

            .icon-hook-selected {
                display: block;
                color: #2DAF61;
            }
        }
    }
}

.csgo-items:hover {
    background-color: #f5fff9;
}
