
.replacing-analog-custom-item {
    //display: flex;
    //justify-content: center;
    cursor: pointer;
    position: relative;
    opacity: 0;
    width: 100%;
    padding: 10px 0 45px 0;
    background-image: url("../../../../../images/items/item_bg.png");
    background-size: 100% 100%;
    transition: 1s opacity;

    > .item-quality {
        position: absolute;
        transform: scale(.8);
        transform-origin: top left;
        left: 0;
        top: 0;
        font-size: 12px;
        display: flex;
        align-items: center;

        > .item_quality {
            background-color: #2A2A2A;
            padding: 8px 10px;
            color: #8F98A0;
        }
    }

    > .replacing-chance, > .material-item-btn-box {
        position: absolute;
        right: 0;
        top: 0px;
        font-size: 12px;
        padding: 2px 4px;
        margin: 0;

        > i {
            transition: .4s;
            cursor: pointer;
            font-size: 14px;
            color: #fff;
            margin-left: 4px;
        }

        > .icon-delete {
            font-size: 18px;
        }

        > .iconfont:hover {
            transform: scale(1.4);
        }
    }

    > .material-item-btn-box {
        top: -3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > .replacing-chance {
        transform: scale(.8);
        transform-origin: top right;
        background-color: #4B8EF2;
        color: #fff;
    }

    > .reAnCustom-item-img {
        width: 60%;
        margin: 0 auto;

        > img {
            width: 81px;
            height: 62.3px;
        }
    }

    > .reAnCustom-item-belongs {
        position: absolute;
        bottom: 24px;
        width: 100%;
        padding: 2px 0px;
        background-color: rgba(0, 0, 0, 0.1);

        > h6 {
            white-space: nowrap;
            transform: scale(.8);
            //transform-origin: left;
            color: #fff;
        }

        text-align: center;
    }

    > .item-wear-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 24px;
        background-color: #393939;
    }

    > .Wear-range {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 15px 5px 5px 5px;
        background-color: #393939;

        > .wear-range-v {
            position: absolute;
            top: -7px;
            left: 6px;
            width: 100%;
            font-size: 12px;
            color: #fff;
            transform: scale(.7);
            transform-origin: bottom left;
        }

        > .wear-show {
            position: relative;
            display: flex;
            width: 100%;
            height: 4px;
            margin: 0 auto;
            border-radius: 5px;
            overflow: hidden;

            > .wear_el-start, > .wear_el-end {
                height: 100%;
                position: absolute;
            }

            > .wear_el {
                height: 100%;
            }


            > .wear_el-start {
                left: 0;
            }


            > .wear_el-end {
                right: 0;
            }
        }
    }
}
