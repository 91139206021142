.filter-search {
  position: absolute;
  right: 0;
  height: 30px;
  display: flex;
  align-items: center;
  > input {
    border-radius: 4px 0 0 4px;
  }
  > .search-btn{
    cursor: pointer;
    width: 68px;
    height: 100%;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    color:  #fff;
    line-height: 30px;
    text-align: center;
    background-color: #2EB062;
  }
}