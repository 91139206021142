/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
    font-family: 'iconfont';  /* Project id 1480761 */
    src: url('//at.alicdn.com/t/c/font_1480761_4otqr87sr1i.woff2?t=1671088484014') format('woff2'),
    url('//at.alicdn.com/t/c/font_1480761_4otqr87sr1i.woff?t=1671088484014') format('woff'),
    url('//at.alicdn.com/t/c/font_1480761_4otqr87sr1i.ttf?t=1671088484014') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-closure:before {
    content: "\e66c";
}

.icon-zhuyi:before {
    content: "\e6e6";
}

.icon-fuzhi:before {
    content: "\e6e4";
}

.icon-close:before {
    content: "\e6ee";
}

.icon-dianzan:before {
    content: "\ec7f";
}

.icon-xiangxia:before {
    content: "\e603";
}

.icon-xiaoxi:before {
    content: "\e67b";
}

.icon-time:before {
    content: "\e6f7";
}

.icon-31pinglun:before {
    content: "\e602";
}

.icon-redu:before {
    content: "\e621";
}

.icon-delete:before {
    content: "\e67e";
}

.icon-copy:before {
    content: "\e633";
}

.icon-message:before {
    content: "\e601";
}

.icon-setting:before {
    content: "\e600";
}

.icon-wenhao:before {
    content: "\e671";
}

.icon-cha:before {
    content: "\e686";
}

.icon-shixinxingxing:before {
    content: "\e609";
}

.icon-xing:before {
    content: "\e7eb";
}

.icon-jinggao:before {
    content: "\e635";
}

.icon-gou:before {
    content: "\e611";
}

.icon-web-switch:before {
    content: "\e6dc";
}

.icon-hook-selected:before {
    content: "\e678";
}

.icon-cs-jt-xs:before {
    content: "\e630";
}

.icon-pen-solid:before {
    content: "\e727";
}

.icon-arrow-right:before {
    content: "\e61f";
}

.icon-arrow-left:before {
    content: "\e620";
}
