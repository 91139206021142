.shop-items {
  width: 160px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 12px;
  color: #222222;

  .bg-icon {
    margin: 0 auto;
    width: 70%;
    background-image: url("../../../../../images/items/item_bg.png");
    background-size: 100% 100%;
  }

  img {
    width: 90%;
    height: auto;
  }
}

.price {
  font-size: 12px;
  color: #41B46D;

  > .price-num {
    font-size: 18px;
  }
}

.end-time {
  color: #999999;
}
