.csgo-item {
  width: 100% !important;
  position: relative;
  padding: 0 !important;
  height: 160px !important;
  >._img {
    position: relative;
    z-index: 0;
  }
}
.select-mask {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  >.select {
    font-size: 35px;
    color: #2DAF61;
  }
}
