@mixin grid {
    display: inline-grid;
    justify-content: space-around;
    align-items: center;
}

.bar-container {
    width: 135.8px;
    height: 105px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 5px;
    overflow: hidden;

    &.block {
        width: 281.6px;

        .bar-body {
            @include grid;
            grid-template-columns: 67.9px 67.9px 67.9px 67.9px;
            justify-items: center;
            font-size: 14px;
            background-color: #fff;

            .bar-body-text {
                width: 60px;
                padding: 6px 0px;
                background-color: #EEEEEE;
                color: #222222;
                font-size: 12px;
                border-radius: 2px;
                text-align: center;
                margin: 6px 0;
                cursor: pointer;
                &:hover{
                    background: #2DAF61;
                }
            }

        }
    }

    .bar-head {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #C9C9C9;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        font-weight: 600;

        .bar-head-text {
            padding-left: 10px;
            &.cur{
                color: #2dAf61;
            }
        }

        .item.arrow-bottom {
            margin-right: 10px;
        }

        .item {
            width: 5px;
            height: 5px;
            border: 1px solid;
        }

        .arrow-bottom {
            font-size: 0;
            line-height: 0;
            border-width: 6px 5px 0 5px;
            border-color: black;
            border-bottom-width: 0;
            border-style: dashed;
            border-top-style: solid;
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    .bar-body {
        width: 100%;
        @include grid;
        grid-template-columns: 67.9px 67.9px;
        justify-items: center;
        font-size: 14px;
        background-color: #fff;

        .bar-body-text {
            display: block;
            width: 56px;
            padding: 6px 0px;
            background-color: #EEEEEE;
            color: #222222;
            font-size: 12px;
            border-radius: 2px;
            text-align: center;
            margin: 6px 0;
            cursor: pointer;
        }
    }
}

.bar-container:hover {
    overflow: visible;
    border-color: transparent;
    z-index: 3;

    .item.arrow-bottom {
        border-top-color: #2dAf61;
    }

    .bar-body {
        box-shadow: 1px 6px 8px 3px #b3b0b0;
        border-radius: 0 0px 4px 4px;
    }
}
