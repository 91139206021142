.ranking-num {
  color: #E59000;
}
.item-shop-name {
  display: flex;
  align-items: center;
  .item-img {
    cursor: pointer;
    width: 130px;
    background-image: url("../../../../../images/items/item_bg.png");
    background-size: 100% 100%;
    img {
      width: 80%;
    }
  }
  .item-name-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 18px;
    .on-sale-price {
      margin-top: 15px;
      .onSale-logo {
        color: #E59000;
        border: 1px solid #E59000;
        padding: 0 3px;
      }
    }
  }
}
