.patternTemplate-select{
  width: 155px;
  .ant-cascader-menu{
    height: auto;
    width: 155px;
    background: white;
  }
  .ant-cascader-menu:nth-child(2){
    position: absolute;
    //top: -200px;
    width: 30px;
    max-height: 200px;
    overflow: scroll;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,0.12), 0 6px 16px 0 rgba(0,0,0,0.08), 0 9px 28px 8px rgba(0,0,0,0.05);
  }
  >div{
    max-height: 135px;
  }

}
