.recharge {
  width: 100%;
  padding: 3rem;
  box-sizing: border-box;

  > .recharge-type {
    > .title {
      font-size: 14px;
      font-weight: 600;
    }

    .recharge-btn {
      background-color: #2DAF61;
      border: none;
      outline: none;
      margin-top: 16px;
    }

    > .type-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 1.6rem;

      .ant-radio-group {
        display: flex;
        flex-direction: column;
      }

      .ant-radio-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .ant-radio-checked .ant-radio-inner {
          border-color: #CCCCCC;

          &::after {
            top: 2px;
            left: 2px;
            width: 10px;
            height: 10px;
            background-color: #2DAF61;
          }
        }
      }

      .type-item {
        display: flex;
        align-items: flex-end;

        > .tip {
          line-height: 35px;
          font-size: 14px;
          color: #666666;
        }

        > img {
          height: 38px;
          width: auto;
        }
      }

      > .type-v {
        // display: flex;
        // align-items: center;
        cursor: pointer;
        position: relative;
        width: 161px;
        height: 52px;
        margin-right: 2rem;
        border: 1px solid #DADADA;
        padding: 6px 35px 8px 19px;
        box-sizing: border-box;
        border-radius: 4px;

        &.current {
          border: 1px solid #2EB062;
        }

        > .icon-type {
          width: 100%;
          height: 100%;
        }

        > .gou {
          position: absolute;
          width: 1.6rem;
          height: 1.6rem;
          right: -1px;
          bottom: -1px;
        }
      }
    }
  }

  > .recharge-amount {
    > .title {
      font-size: 14px;
      font-weight: 600;
    }

    > .amount-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 1.6rem 0 2rem 0;

      > .amount-op {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding: 1rem .8rem;
        color: #C2C2C2;
        border: 1px solid #DADADA;
        border-radius: 4px;
        margin-left: 1rem;
        font-size: 14px;

        > .gou {
          position: absolute;
          width: 1.6rem;
          height: 1.6rem;
          right: -1px;
          bottom: -1px;
        }

        &.current {
          color: #2EB062;
          border: 1px solid #2EB062;
        }
      }
    }

    > .recharge-send {
      cursor: pointer;
      border-radius: 4px;
      padding: .6rem .95rem;
      background-color: #2EB062;
      color: #fff;
      font-size: 1.1rem;
    }
  }

  > .recharge-prompt {
    margin-top: 35px;
    width: 90%;
    background-size: 100% 100%;
    box-sizing: border-box;
    border: 3px dashed #e2e2e2;
    padding: 20px 50px;

    .prompt-v {
      color: #343434;
      font-size: 14px;
      margin: 8px 0;
    }
  }

  > .recharge-link {
    margin: 30px 0 20px 10px;
    cursor: pointer;
    color: #2EB062;
    font-size: 16px;

    .icon-arrow-right {
      font-size: 14px;
    }
  }

}
