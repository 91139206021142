.order-detail-container {
  > .order-detail-table-container {
    min-height: 400px;
    max-height: 500px;
    .ant-table-body::-webkit-scrollbar {
      width: 6px;
      height: 1px;
    }
    .ant-table-body::-webkit-scrollbar-track {
      background: #D3D3D3;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
      background: #989898;
      border-radius: 0.4rem;
    }
  }
  > .order-detail-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px 0 25px;
    box-sizing: border-box;
    position: relative;
     >.income {
      display: flex;
      margin-top: 10px;
      align-items: center;
      font-size: 16px;
      color: #999999;
      font-weight: 400;
      margin-bottom: 5px;
      > .all-price {
        font-size: 24px;
        color: rgb(240, 147, 41);
        > .item-num {
          font-size: 16px;
        }
      }
    }
  }
}