.promote-manag {
  width: 98%;
  padding: 3rem;
  box-sizing: border-box;

  .promote-container {
    position: static;

    > .head-title {
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 100px;
      }
    }

    > .promote-table {
      margin-top: 60px;
      width: 100%;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;

      > .tab-list {
        display: flex;
        background-color: #f5f5f5;
        font-weight: 500;

        &.plural {
          background-color: #fff;
        }

        > .tab-item {
          font-size: 1rem;
          display: flex;
          flex: 1;
          text-align: center;
          padding: 25px 12px;
          justify-content: center;
          border-left: 1px solid #E5E5E5;
          box-sizing: border-box;
          align-items: center;

          > .tab-icon {
            width: 110px;
          }

          &.title {
            flex: .7;
            border: none;
          }
        }
      }
    }

    > .promote-prompt {
      margin-top: 35px;
      width: 90%;
      background-size: 100% 100%;
      border: 3px dashed #e2e2e2;
      padding: 20px 50px;
      box-sizing: border-box;

      .prompt-v {
        color: #343434;
        font-size: 14px;
        margin: 6px 0;
      }
    }
  }
}
