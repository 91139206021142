.gradient-input{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gradient-title{
  font-weight: 600;
  font-size: 23px;
}
.gradient-btn{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .gradient-btn-cancel{
    background-color: #2DAF61;
    color: white;
  }
  .gradient-btn-submit{
    background-color: #E0FFEC;
  }
}
